import { Box } from '@chakra-ui/react';
import { GridCellSize } from '@components/Grid';
import { MotionBox } from '@components/MotionBox';
import { useCarouselContext } from '@components/Carousel';
import * as React from 'react';
import AspectRatioWrapper from '@components/AspectRatioWrapper';
import TransitionBox from '@components/TransitionBox';
import Vimeo from '@vimeo/player';

const getVimeoVideoParameters = (vimeoOptions: 'background' | 'autoplay' | 'default' | undefined) => {
	if (vimeoOptions === 'background') {
		return '?background=true&transparent=true&autopause=false';
	}
	else if (vimeoOptions === 'autoplay') {
		return '?autoplay=true&byline=false&color=ffffff&loop=true&muted=true&portrait=false&title=false&transparent=true&autopause=false';
	}

	return '?byline=false&color=ffffff&loop=true&portrait=false&title=false&transparent=true&autopause=false';
};

export interface VimeoModuleProps {
	height?: number;
	isCarouselItem?: boolean;
	options?: 'background' | 'autoplay';
	playerOptions?: 'autoplay' | 'background' | 'default'; // TODO fk refactor, break out into separate props
	position?: string;
	size?: GridCellSize;
	span?: number;
	verticalAlign?: string;
	vimeoId: string;
	width?: number;
}

export const VimeoModule: React.FC<VimeoModuleProps> = (props) => {
	const aspectRatio = props.width / props.height;
	const videoParams = getVimeoVideoParameters(props.playerOptions); // TODO: refactor
	const ref = React.createRef<HTMLIFrameElement>();
	const video = {
		src: `https://player.vimeo.com/video/${props.vimeoId}${videoParams}`,
	};

	const [vimeoSrc, setVimeoSrc] = React.useState(video.src);

	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isPlaying, setIsPlaying] = React.useState(false);

	const carouselContext = useCarouselContext();

	const onLoaded = React.useCallback(() => {
		setIsLoaded(true);
	}, [setIsLoaded]);

	let playerReference;

	const getPlayer = React.useCallback(() => {
		if (!playerReference) {
			playerReference = new Vimeo(ref.current);
		}

		return playerReference;
	}, [ref.current, carouselContext?.isActive, isPlaying]);

	// mk: I think the goal here was to autoplay these videos and have them
	// advance to the next carousel item once they were done playing. I'm
	// temporarily removing that because it doesn't appear to work as-is
	// If we want to come back, the "carouselContext.requestPlay" attempts
	// to advance to the next slide
	React.useEffect(() => {
		const player = getPlayer();

		if (player && carouselContext) {
			player.setLoop(false);

			player.on('play', () => {
				carouselContext?.requestPause();
				setIsPlaying(true);
			});

			player.on('pause', (data) => {
				const hasEnded = data.percent == 1;
				if (!hasEnded) {
					// carouselContext?.requestPlay();
					setIsPlaying(false);
				}
			});

			player.on('ended', () => {
				// carouselContext?.requestPlay();
				setIsPlaying(false);
			});
		}
	}, [ref, getPlayer, carouselContext, setIsPlaying, setIsLoaded]);

	// stop when carousel plays
	// React.useEffect(() => {
	//   const player = getPlayer();

	//   if (player && carouselContext?.isActive == true && isPlaying) {
	//     player.unload();
	//   }
	// }, [carouselContext?.isActive, isPlaying, getPlayer]);

	React.useEffect(() => {
		const player: Vimeo = getPlayer();

		if (isPlaying) {
			// new Vimeo(ref.current)?.unload();
			player?.unload();
		}
	}, [carouselContext?.isActive]);

	return (
		<AspectRatioWrapper ratio={aspectRatio ?? 16 / 9}>
			<TransitionBox isLoaded={isLoaded}>
				<Box ref={ref} as="iframe" src={vimeoSrc} boxSize="100%" frameBorder={0} onLoad={onLoaded} allow="autoplay; fullscreen" />
			</TransitionBox>
		</AspectRatioWrapper>
	);
};

VimeoModule.defaultProps = {
	width: 1920,
	height: 1080,
};
