import * as React from 'react';
import { Asset } from '@lib/types';
import StyledSanityImage from './SanityImage';

export const SanityAsset: React.FC<{ asset: Asset }> = (props) => {
	if (props.asset?.mimeType === 'image/gif') {
		return <StyledSanityImage asset={props.asset} />;
	}
	else if (props.asset?.mimeType === 'image/png') {
		return <StyledSanityImage asset={props.asset} />;
	}
	else if (props.asset?.mimeType === 'image/jpeg') {
		return <StyledSanityImage asset={props.asset} />;
	}
	else if (props.asset?.mimeType === 'video/mp4' || props.asset?.mimeType === 'video/quicktime') {
		return <video src={props.asset.url} autoPlay muted loop playsInline></video>;
	}
	else if (props.asset) {
		return <StyledSanityImage asset={props.asset} />;
	}
	else {
		return null;
	}
};
