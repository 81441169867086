export const gridColumnMap = {
	col1: 1,
	col2: 2,
	col3: 3,
	col4: 4,
	col5: 5,
	col6: 6,
	col7: 7,
	col8: 8,
};

export const gridSpanMap = {
	span1: 1,
	span2: 2,
	span3: 3,
	span4: 4,
	span5: 5,
	span6: 6,
	span7: 7,
	span8: 8,
	full: 8,
};

type GridColumns = keyof typeof gridColumnMap;
type GridSpans = keyof typeof gridSpanMap;

export interface Row {
	items: any[];
	count: number;
	previousColumn: number;
}

export interface LayoutItem {
	gridColumn?: GridColumns;
	gridSpan?: GridSpans;
}

export const layoutItemsToRows = <T extends LayoutItem>(items: T[], { debug = false, gridSpan = 8 }: { debug?: boolean; gridSpan?: number } = {}) => {
	if (!items) return [];
	const rows: Row[] = [{ items: [], count: 0, previousColumn: 0 }];
	items.forEach((item) => {
		const row = rows[rows.length - 1];
		// calc this item's column and span
		const column = columnToNumber(item.gridColumn);
		const span = spanToNumber(item.gridSpan);

		const didRequestColumn = !!column;

		// has set column, it either is available, or we go to the next row
		if (didRequestColumn) {
			const columnNormalized = column - 1;
			const isRequestedColumnVacent = column >= row.previousColumn;
			const endColumn = columnNormalized + span;
			if (isRequestedColumnVacent) {
				row.items.push(item);
				row.previousColumn = endColumn;
				row.count += 1;
				return;
			} else {
				rows.push({
					items: [item],
					count: 1,
					previousColumn: endColumn,
				});
				return;
			}
			// is just a span, and can float to the next available column if theres a enough space in the row
		}
		else {
			const startColumn = row.previousColumn + 1;
			const endColumn = row.previousColumn + span;

			const canFitInCurrentRow = endColumn <= gridSpan;
			if (canFitInCurrentRow) {
				row.items.push(item);
				row.previousColumn = endColumn;
				row.count += 1;
				if (debug) {
					item['debug'] = { canFitInCurrentRow, previousColumn: row.previousColumn, startColumn, endColumn };
				}
				return;
			} else {
				rows.push({
					items: [item],
					count: 1,
					previousColumn: span,
				});
				if (debug) {
					item['debug'] = { canFitInCurrentRow, previousColumn: span + 1, startColumn, endColumn };
				}
				return;
			}
		}
	});

	return rows;
};

export function getGridColumn({ gridColumn, gridSpan, numColumns = 8 }: { gridColumn?: GridColumns; gridSpan?: GridSpans; numColumns?: number }) {
	const column = columnToNumber(gridColumn);
	const span = spanToNumber(gridSpan);

	if (column && span) {
		const total = column + span;
		if (total > numColumns) {
			return `${column}/-1`;
		}
		return `${column}/span ${span}`;
	}
	else if (column) {
		if (column > numColumns) {
			return `7/-1`;
		}
		return `${column}/-1`;
	}
	else if (span) {
		if (span > numColumns) {
			return `span 8`;
		}
		return `span ${span}`;
	}
	else {
		return `1/-1`;
	}
}

export const columnToNumber = (gridColumn: GridColumns) => gridColumnMap[gridColumn];
export const spanToNumber = (gridSpan: GridSpans) => gridSpanMap[gridSpan];
