import { forwardRef, BoxProps, Box, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const JobPosition = forwardRef<BoxProps & { title: string; url: string }, 'div'>(({ title, url, ...props }, ref) => {
	return (
		<Box role="group" _hover={{ position: 'relative' }}>
			<Box outline="1px solid rgb(220,220,220)" _groupHover={{ outline: '1px solid rgb(0,0,0)', zIndex: '9999' }} />
			<Flex as={Link} justify="space-between" alignItems="center" href={url} isExternal>
				<Box py="4" transition="all .2s ease-in-out" position="relative" ref={ref} {...props}>
					<Text as="span" className="title">
						{title}
					</Text>
				</Box>
				<Text as="span" className="icon">
					↗
				</Text>
			</Flex>
			<Box outline="1px solid rgb(220,220,220)" _groupHover={{ outline: '1px solid rgb(0,0,0)', zIndex: '9999' }} />
		</Box>
	);
});

export default JobPosition;
