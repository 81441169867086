import { forwardRef, BoxProps, Box, Flex, Link, Text, GridItem } from '@chakra-ui/react';
import { getStructuredJobsByLocation, IJob } from '@components/jobs';
import { Grid8 } from '@components/Grid';
import { JobPosition } from './JobPosition';
import React from 'react';

interface JobsProps {
	jobs: IJob[];
}

export const JobsOpenPositions = forwardRef<BoxProps & JobsProps, 'div'>(({ jobs, ...props }, ref) => {
	const structuredJobs = getStructuredJobsByLocation(jobs);

	return (
		<Grid8 rowGap={{ base: '12', lg: '24' }} ref={ref} flush="horizontal">
			{structuredJobs.map((location) => {
				<GridItem gridColumn={{ base: '1/-1', sm: '2/span 2' }}>
					<Text as="h3">Hello</Text>
				</GridItem>;
				return (
					<React.Fragment key={location.location}>
						<GridItem gridColumn={{ base: '1/-1', sm: '2/span 2' }}>
							<Text as="h3">{location.city}</Text>
						</GridItem>
						<GridItem gridColumn={{ base: '1/-1', sm: '4/-1' }}>
							{location.jobs.map((job, i) => {
								return <JobPosition key={i} title={job.title} url={job.absoluteUrl} />;
							})}
						</GridItem>
					</React.Fragment>
				);
			})}
		</Grid8>
	);
});
