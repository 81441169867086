import * as React from 'react';
import * as ui from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import dynamic from 'next/dynamic';
import isFinite from 'lodash/isFinite';

import { JobsOpenPositions } from '../jobs/JobsOpenPositions';
import OfficeInfoGrid from './OfficeInfoGrid';

function DynamicLoading() {
	return <ui.Skeleton height="100px" />;
}

export const Eyepatch = dynamic(() => import('../interactive/eyepatch'), {
	loading: DynamicLoading,
	ssr: false,
});

export const BuckyWallArrows = dynamic(() => import('../interactive/buckywall-arrows'), {
	loading: DynamicLoading,
	ssr: false,
});

export const BuckyWallFace = dynamic(() => import('../interactive/buckywall-face-drag'), {
	loading: DynamicLoading,
	ssr: false,
});

// export const Slapstick = dynamic(() => import('@buck/website-component-slapstick'), {
//   ssr: false,
//   loading: DynamicLoading
// });

export const Slime = dynamic(() => import('../interactive/slime'), { ssr: false, loading: DynamicLoading });

const linkIdToInteractiveComponentMap: {
	[key: string]: { useAspectRatio?: boolean; forceAspectRatio?: number; component: React.ComponentType<any> };
} = {
	'eye-patch-webgl': {
		component: Eyepatch,
		useAspectRatio: true,
	},
	'buckywall-face-drag': {
		component: BuckyWallFace,
		forceAspectRatio: 1,
		useAspectRatio: false,
	},
	'buckywall-pointing-arrows': {
		component: BuckyWallArrows,
		forceAspectRatio: 1,
		useAspectRatio: true,
	},
	// 'slapstick-sticker-xoxo-05': {
	//   useAspectRatio: true,
	//   component: Slapstick
	// },
	'ray-marching-slime': {
		component: Slime,
		useAspectRatio: true,
	},
	'open-positions': {
		component: JobsOpenPositions,
	},
	'office-info-grid': {
		component: OfficeInfoGrid,
	},
	'verizon-retail-ar': {
		component: function IframeVerizonRetailAR() {
			return <ui.Box as="iframe" src="/iframe/verizon-retail-ar/index.html" boxSize="100%" />;
		},
		useAspectRatio: true,
	},
	'industry-city-mural': {
		component: function IframeIndustryCityMural() {
			return <ui.Box as="iframe" src="/iframe/industry-city-mural/index.html" boxSize="100%" />;
		},
		useAspectRatio: true,
	},
};

export const ComponentFactory: React.FC<{ id: string; width: number; height: number; lazyLoadOffset?: number }> = ({
	id,
	height,
	width,
	lazyLoadOffset = 300,
	...props
}) => {
	if (!linkIdToInteractiveComponentMap.hasOwnProperty(id)) {
		console.warn(`unknown component "${id}"`);
		return null;
	}

	const { component: Component, useAspectRatio, forceAspectRatio } = linkIdToInteractiveComponentMap[id];

	const aspectRatio = forceAspectRatio ?? width / height;

	if (useAspectRatio && isFinite(aspectRatio)) {
		return (
			<ui.AspectRatio ratio={aspectRatio}>
				<LazyLoad height={height} offset={lazyLoadOffset}>
					<Component />
				</LazyLoad>
			</ui.AspectRatio>
		);
	}

	return (
		<LazyLoad offset={lazyLoadOffset}>
			<Component {...props} />
		</LazyLoad>
	);
};
