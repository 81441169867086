import { forwardRef, BoxProps, Box, ImageProps, Image } from '@chakra-ui/react';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { MotionBox } from './MotionBox';
import { SanityImageAsset } from '@sanity/asset-utils';
import { urlFor } from '@lib/sanity';
import { useGridAlignment } from './GridAlignmentWrapper';
import React from 'react';

const alignmentMap = {
	left: '0%',
	center: '50%',
	right: '100%',
};

export type ImageAlignment = keyof typeof alignmentMap;

export const StyledSanityImage = forwardRef<
	BoxProps & { asset: SanityImageAsset; options?: ImageUrlBuilder['options']; alignment?: ImageAlignment },
	'div'
>(({ asset, options, ...props }, ref) => {
	const imgRef = React.useRef<HTMLImageElement>();
	const [loaded, setLoaded] = React.useState(false);
	const { alignment, ...rest } = props;

	// handle if image is already loaded before js
	React.useEffect(() => {
		if (imgRef.current) {
			if (!loaded && imgRef.current.complete) {
				setLoaded(true);
			}
		}
	}, [loaded, imgRef]);

	// handle image loading after js
	const onLoad = React.useCallback(() => {
		setLoaded(true);
	}, [imgRef]);

	const gridAlignment = useGridAlignment();

	return (
		<Box
			className="image"
			ref={ref}
			boxSize={gridAlignment?.gridHeight == 'row' && '100%'}
			bg={loaded ? 'transparent' : 'brand.gray.200'}
			{...rest}
		>
			<MotionBox
				boxSize="100%"
				animate={loaded ? { opacity: 1 } : { opacity: 0 }}
				sx={
					gridAlignment?.gridHeight == 'row' && {
						objectFit: 'cover',
						objectPosition: '50% 50%',
						overflow: 'hidden',
						position: 'relative',
						verticalAlign: 'middle',
					}
				}
			>
				<SanityImage ref={imgRef} asset={asset} options={options} onLoad={onLoad} alignment={alignment} />
			</MotionBox>
		</Box>
	);
});

function SanityImageSource(props: { asset: SanityImageAsset; width: number; mediaMinWidth: number }) {
	return (
		<source
			srcSet={`${urlFor(props.asset).width(props.width).quality(95).auto('format').url()}`}
			media={`(min-width: ${props.mediaMinWidth}px)`}
		/>
	);
}

export const SanityImage = forwardRef<
	ImageProps & { asset: SanityImageAsset; options?: ImageUrlBuilder['options']; alignment: ImageAlignment },
	'div'
>(({ asset, options = {}, ...props }, ref) => {
	const { alignment = 'center', ...rest } = props;

	const gridAlignment = useGridAlignment();

	const effectiveAlignment = alignmentMap[alignment];
	const src = urlFor(asset).width(600).quality(95).auto('format').url();

	return (
		<picture>
			<SanityImageSource asset={asset} width={2000} mediaMinWidth={1600} />
			<SanityImageSource asset={asset} width={1400} mediaMinWidth={1200} />
			<SanityImageSource asset={asset} width={1000} mediaMinWidth={800} />
			<SanityImageSource asset={asset} width={800} mediaMinWidth={600} />
			<Image
				alt=""
				className="image"
				loading="lazy"
				objectFit="cover"
				objectPosition={`${effectiveAlignment} 50%`}
				overflow="hidden"
				ref={ref}
				src={src}
				sx={gridAlignment?.gridHeight == 'row' && { position: 'absolute', top: '0', left: 0, height: '100%' }}
				width="100%"
				{...rest}
			/>
		</picture>
	);
});

export default StyledSanityImage;
