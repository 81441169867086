import * as React from 'react';
import { Flex } from '@chakra-ui/react';

const gridAlignMap = {
	bottom: 'flex-end',
	middle: 'center',
	top: 'flex-start',
};

export type GridAlignment = keyof typeof gridAlignMap;

const gridJustifyMap = {
	left: '0%',
	center: '50%',
	right: '100%',
};

export type GridAlignmentJustify = keyof typeof gridJustifyMap;

export type GridAlignmentHeight = 'row' | 'media';

export interface GridAlignmentWrapperProps {
	gridAlign?: GridAlignment;
	gridHeight?: GridAlignmentHeight;
	gridJustify?: GridAlignmentJustify;
}

export const GridAlignmentWrapperContext = React.createContext<GridAlignmentWrapperProps>(undefined);

export const useGridAlignment = () => React.useContext(GridAlignmentWrapperContext);

export const GridAlignmentWrapper: React.FC<GridAlignmentWrapperProps> = (props) => {
	if (!props.gridAlign) return <GridAlignmentWrapperContext.Provider value={props}>{props.children}</GridAlignmentWrapperContext.Provider>;

	return (
		<GridAlignmentWrapperContext.Provider value={props}>
			<Flex data-ui="GridAlignmentWrapper" boxSize="100%" alignItems={gridAlignMap[props.gridAlign]}>
				{props.children}
			</Flex>
		</GridAlignmentWrapperContext.Provider>
	);
};

export default GridAlignmentWrapper;
