import { Link } from '@chakra-ui/react';
import * as React from 'react';

export const ExternalArrowLink: React.FC<{ href: string }> = (props) => {
	return (
		<>
			(
			<Link href={props.href} isExternal>
				{props.children && props.children + ' '}↗
			</Link>
			)
		</>
	);
};

export default ExternalArrowLink;
