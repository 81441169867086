import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { css } from '@emotion/react';
import * as React from 'react';

export const TextBox = forwardRef<BoxProps, 'div'>((props, ref) => {
	return (
		<Box
			ref={ref}
			css={css`
				a {
					opacity: 0.5;
					transition: all 0.2s ease-in-out;

					&:hover {
						opacity: 1;
					}
				}

				ol,
				ul {
					margin: 1em;
				}

				p + p {
					margin-top: 1em;
				}
			`}
			{...props}
		>
			{props.children}
		</Box>
	);
});

export default TextBox;
