import { ArrowLeft, ArrowRight } from './Icons';
import { Box, Grid, Flex, Button } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { MotionBox } from './MotionBox';
import { useControllableInterval } from '@hooks';
import { useGridCellContext } from './GridCellContext';
import * as React from 'react';

export const CarouselContext = React.createContext<{
	isActive?: number; // mk: Change came from Taylor's PR
	requestPause: () => boolean;
	requestPlay: () => boolean;
}>(undefined);

export const useCarouselContext = () => React.useContext(CarouselContext);

interface CarouselItemProps {
	children?: React.ReactNode | readonly React.ReactNode[];
	currentIndex?: number;
	direction?: 'left' | 'right';
	index?: number;
	isActive?: boolean;
	onAnimationComplete?: () => void;
	previousIndex?: number;
	transitionDuration?: number;
}

export const CarouselItem: React.FC<CarouselItemProps> = (props) => {
	const controls = useAnimation();
	const isCurrent = props.index == props.currentIndex;
	const isPrevious = props.index == props.previousIndex;
	const animateFrom = isPrevious ? '0%' : props.direction == 'right' ? '100%' : '-100%';

	const variants = {
		initial: (direction: string) => {
			return {
				x: direction == 'right' ? '100%' : '-100%',
			};
		},
		enter: {
			x: '0%',
		},
		exit: (direction: string) => {
			return {
				x: direction == 'right' ? '-100%' : '100%',
			};
		},
	};

	React.useEffect(() => {
		isCurrent && controls.start('enter');
		isPrevious && controls.start('exit');
	}, [props.currentIndex]);

	return (
		<motion.div
			animate={controls}
			className="carousel--item"
			custom={props.direction}
			initial="initial"
			key={props.index}
			style={{ height: '100%', width: '100%', position: 'absolute', top: 0 }}
			variants={variants}
			transition={{
				x: {
					from: animateFrom, // isPrevious ? '0%' : props.direction == 'right' ? '100%' : '-100%',
					type: 'spring',
					bounce: 0,
					mass: 0.3,
				},
			}}
		>
			{props.children}
		</motion.div>
	);
};

CarouselItem.defaultProps = {
	direction: 'right',
};

export const Carousel: React.FC<{
	aspectRatio?: number;
	autoplay?: boolean;
	arrowColor?: string;
	children?: React.ReactNode | readonly React.ReactNode[];
}> = (props) => {
	const cellContext = useGridCellContext();
	const childrenLength = React.Children.count(props.children);
	const interval = useControllableInterval({
		autoStart: props.autoplay,
		interval: 3000,
		loop: childrenLength,
	});

	const counterValue = interval.value + 1;

	const childrenWithIndex = React.Children.map(props.children, (child, i) =>
		React.cloneElement(child as React.ReactElement, {
			currentIndex: interval.value,
			direction: interval.direction,
			index: i,
			isActive: interval.value == i,
			previousIndex: interval.previousValue,
		}),
	);

	const inverseAspectRatio = 1 / props.aspectRatio;
	const arrowColor = props.arrowColor ?? 'white';

	const requestPlay = React.useCallback(() => {
		interval.start();
		return true;
	}, [interval]);

	const requestPause = React.useCallback(() => {
		interval.pause();
		return true;
	}, [interval]);

	return (
		<Box className="carousel">
			{/* {interval.debugControls} */}
			<Box width="100%" height="0" paddingTop={`${inverseAspectRatio * 100}%`} overflow="hidden" position="relative">
				<Grid
					bottom="0"
					gridAutoColumns="100%"
					gridAutoFlow="column"
					gridAutoRows="100%"
					left="0"
					overflow="hidden"
					position="absolute"
					right="0"
					top="0"
				>
					<CarouselContext.Provider
						value={{
							requestPlay,
							requestPause,
							isActive: interval.value, // interval.active
						}}
					>
						{childrenWithIndex}
					</CarouselContext.Provider>
				</Grid>
				<MotionBox
					alignItems="center"
					bottom="0"
					display="flex"
					justifyContent="space-between"
					left="0"
					pointerEvents="none"
					position="absolute"
					right="0"
					top="0"
				>
					<Flex boxSize="100%" justifyContent="center" alignItems="flex-start">
						<Box
							cursor={`url(/images/arrow-left-${arrowColor}.svg) 100 32,url(/images/arrow-left.png) 100 32,pointer`}
							height={{ base: '65%', md: '80%' }}
							onClick={interval.previous}
							outline="none"
							pointerEvents="all"
							width="100%"
						></Box>
					</Flex>
					<Box boxSize="100%" />
					<Flex boxSize="100%" justifyContent="center" alignItems="flex-start">
						<Box
							cursor={`url(/images/arrow-right-${arrowColor}.svg) 100 32,url(/images/arrow-right.png) 100 32,pointer`}
							height={{ base: '65%', md: '80%' }}
							onClick={interval.next}
							outline="none"
							pointerEvents="all"
							width="100%"
						></Box>
					</Flex>
				</MotionBox>
			</Box>
			<Flex py={4} justifyContent={{ base: 'space-between', sm: 'flex-end' }} alignItems="center">
				<Button onClick={interval.previous} aria-label="Next" p={0} display={{ sm: 'none' }}>
					<ArrowLeft fontSize="2xl" />
				</Button>
				<Box pr={{ base: 0, sm: cellContext?.gridSpan == 'full' ? 4 : 0 }}>
					{counterValue} / {childrenLength}
				</Box>
				<Button onClick={interval.next} aria-label="Next" p={0} display={{ sm: 'none' }}>
					<ArrowRight fontSize="2xl" />
				</Button>
			</Flex>
		</Box>
	);
};

Carousel.defaultProps = {
	aspectRatio: 16 / 9,
	autoplay: false, // mk: previously: true
};

export default Carousel;
