export interface IJob {
	absoluteUrl: string;
	id: string;
	locationName: string;
	title: string;
}

export function getStructuredJobsByLocation(jobs: IJob[]) {
	const jobsGroupedByLocation =
		jobs.filter(Boolean)?.reduce<{ [key: string]: IJob[] }>((r, job) => {
			r[job.locationName] = [...(r[job.locationName] || []), job];
			return r;
		}, {}) ?? [];

	const globalJobs = jobsGroupedByLocation['Global/Remote'];
	delete jobsGroupedByLocation['Global/Remote'];

	const globalJobsLocation = ['Global/Remote', globalJobs] as [string, IJob[]];

	const allJobs = [...Object.entries(jobsGroupedByLocation), globalJobsLocation].filter(([location, jobs]) => location && jobs);

	return allJobs.map(([location, jobs]) => {
		const city = location.split(',')[0];
		return {
			city,
			jobs,
			location,
		};
	});
}
