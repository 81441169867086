import { MotionBox } from '@components/MotionBox';
import * as React from 'react';

export const TranstionBox: React.FC<{ isLoaded: boolean }> = (props) => {
	return (
		<MotionBox
			animate={props.isLoaded ? 'enter' : 'exit'}
			boxSize="100%"
			initial="initial"
			variants={{
				initial: { backgroundColor: 'rgba(0,0,0,.1)' },
				enter: { backgroundColor: 'rgba(0,0,0,0)', transition: { duration: 1, delay: 0.5 } },
			}}
		>
			<MotionBox
				animate={props.isLoaded ? 'enter' : 'exit'}
				boxSize="100%"
				initial="initial"
				variants={{
					initial: { opacity: 0 },
					enter: { opacity: 1, transition: { duration: 0.5, delay: 1 } },
				}}
			>
				{props.children}
			</MotionBox>
		</MotionBox>
	);
};

export default TranstionBox;
