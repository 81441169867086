import * as React from 'react';
import ExternalArrowLink from '@components/ExternalArrowLink';
import TextBox from '@components/TextBox';
import { Grid8 } from '@components/Grid';
import { SiteMetadata } from '@lib/api';
import { Stack, Box, VStack, Link, GridItem } from '@chakra-ui/react';

interface OfficesGridProps {
	offices: SiteMetadata['site']['offices'];
}

export const OfficeInfo: React.FC<{
	addressLines: string[];
	name: string;
	timezone: string;
	emailInquiries: string;
	phone: string;
	indigenousLand?: string;
	indigenousLandLink?: string;
}> = (props) => {
	return (
		<Stack direction={{ base: 'row', sm: 'column' }} spacing={2} align="stretch">
			<Box flex={1}>
				<TextBox as="h3" noOfLines={2} mb={4}>
					<div>{props.name}</div>
				</TextBox>
			</Box>
			<Box flex={1} overflow="hidden">
				<VStack spacing={4} align="stretch">
					<TextBox noOfLines={3}>
						<>
							{props.addressLines[0]}
							<br />
							{props.addressLines[1]}
							<br />
							{props.addressLines[2]}
						</>{' '}
						<ExternalArrowLink href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(props.addressLines.join(', '))}`}>
							Map it
						</ExternalArrowLink>
					</TextBox>
					<TextBox>
						<h4>Phone:</h4>
						<Link href={`tel:${props.phone}`} isExternal>
							{props.phone}
						</Link>
					</TextBox>
					<TextBox>
						<h4>New Business:</h4>
						<Link href={`mailto:${props.emailInquiries}`}>{props.emailInquiries}</Link>
					</TextBox>
					{props.indigenousLand && (
						<TextBox>
							{props.indigenousLand} {props.indigenousLandLink && <ExternalArrowLink href={props.indigenousLandLink} />}
						</TextBox>
					)}
				</VStack>
			</Box>
		</Stack>
	);
};

const OfficeInfoGrid: React.FC<OfficesGridProps> = (props) => {
	return (
		<Grid8 rowGap={24} flush="horizontal">
			{props.offices.map((office, i) => (
				<GridItem key={office._id} colSpan={{ base: 8, sm: 4, md: 2 }} colStart={{ sm: 'auto' }}>
					<OfficeInfo
						addressLines={[office.address.line1, office.address.line2, office.address.line3]}
						name={office.name}
						emailInquiries={office.emailInquiries}
						phone={office.phone}
						timezone={office.timezone}
						indigenousLand={office.indigenousLand}
						indigenousLandLink={office.indigenousLandLink}
					/>
				</GridItem>
			))}
		</Grid8>
	);
};

export default OfficeInfoGrid;
