import * as React from 'react';
import { AspectRatio } from '@chakra-ui/react';

export const AspectRatioWrapper: React.FC<{
	ratio?: number
}> = (props) => {
	if (props.ratio == undefined) return <>{props.children}</>;

	return (
		<AspectRatio ratio={props.ratio} width="100%">
			{props.children}
		</AspectRatio>
	);
};

export default AspectRatioWrapper;
